import React from 'react';
import BoundaryIframe from '../components/BoundaryIframe';
import Header from '../components/Header';

function WheatYield() {
  return (
    <>
      <Header title="Forecast Wheat Yield"/>
      <BoundaryIframe url="https://realfield-398821.projects.earthengine.app/view/wheat-yield" title="Forecast Wheat Yield" />
    </>
  );
}

export default WheatYield;
