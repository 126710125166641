import React from 'react';
import MapComp from '../components/MapComp';
import ResponsiveAppBar from '../components/ResponsiveAppBar'

function Boundary() {
  return (
    <>
      <ResponsiveAppBar/>
      <MapComp  />          
    </>
  );
}

export default Boundary;
