import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

export default function FieldInfoDialog({ open, setOpen, fieldId }){
    const theme = useTheme();
    const [loading,setLoading]  = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [properties, setProperties] = React.useState({})
    
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        setLoading(true)
        async function fetchMyAPI() {
            let response = await fetch(`https://api.realfield.ca/boundary/?field_id=${fieldId}`)
            response = await response.json()
            setSuccess(Boolean(response['success']))
            setProperties(response['properties'])
            setLoading(false)
        }
    
        fieldId && fetchMyAPI()
      }, [fieldId])
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {`Field ${fieldId} information`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                {loading&&
                <CircularProgress/>
                }
                {!loading&&!success&&
                    <div>Error - Please check your enter field ID</div>
                }
                {!loading&&success&&properties&&
                    <>
                        <div style={{fontWeight:'bold',color:"black"}}>{properties['field_id']}</div>
                        <div >Crop 2019: {properties['crop_2019']}</div>
                        <div >Area: {properties['Shape_Area']}</div>
                    </>
                }                
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog> 
    );
}