import * as React from 'react';

function Header({title="RealField"}) {
  
  return (
    <div id="header">
        <div id="logo">
          <img src="/images/avatar/2.jpg"/>
        </div>
        <div id="slogan">{title}</div>     
    </div>
  );
}
export default Header;