import React from 'react';
import BoundaryIframe from '../components/BoundaryIframe';
import Header from '../components/Header';

function TerraClimate() {
  return (
    <>
      <Header title="Terra Climate"/>
      <BoundaryIframe url="https://realfield-398821.projects.earthengine.app/view/terra-climate" title="Terra Climate" />
    </>
  );
}

export default TerraClimate;
