import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Polygon, Tooltip, useMapEvent, LayersControl, LayerGroup } from 'react-leaflet';
import "./Map.css";
import "leaflet/dist/leaflet.css";
import FieldInfoDialog from "./FieldInfoDialog";
const convertToGeeGeometry = (west, south, east, north) => {
    const geoJson = {
        type: 'Polygon',
        coordinates: [
            [
                [west, south],
                [east, south],
                [east, north],
                [west, north],
                [west, south]
            ]
        ]
    };

    // Convert GeoJSON to GEE geometry string
    const geeGeometry = JSON.stringify(geoJson);

    return geeGeometry;
};
const isPointInsidePolygon = (point, polygonPath) => {
    let inside = false;
    const { x, y } = point;

    for (
        let i = 0, j = polygonPath.length - 1;
        i < polygonPath.length;
        j = i++
    ) {
        const xi = polygonPath[i].x,
            yi = polygonPath[i].y;
        const xj = polygonPath[j].x,
            yj = polygonPath[j].y;

        const intersect =
            yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

        if (intersect) {
            inside = !inside;
        }
    }

    return inside;
};
function MapComp() {
    const [highlightedPolygon, setHighlightedPolygon] = useState(null);
    const [mapData, setMapData] = useState([]);
    const [mapBounds, setMapBounds] = useState(undefined);
    const [loaded, setLoaded] = useState(false)
    const [open, setOpen] = useState(false)
    
    const mapRef = useRef(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.realfield.ca/boundaries-all', {
                // const response = await fetch('https://api-owaid5xuua-uc.a.run.app/boundaries', {
                    method: 'POST',
                    headers: {
                        "x-rapidapi-host": "https://api.realfield.ca",
                        "x-rapidapi-key": "apikey",
                        "content-type": "application/json",
                        "accept": "application/json"
                    }
                });
                const data = await response.json();
                // console.log(data)
                setMapData(data);
                setLoaded(true)
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
        
    }, []);
    
    const ChangeMapView = () => {
        const map = useMapEvent('moveend', () => {
            const bounds = map.getBounds();
            setMapBounds(bounds);
        });

        useMapEvent('mousemove', (e) => {
            const map = e.target;
            const zoom = map.getZoom();

            // Check if the mouse is over a polygon
            if (zoom >= 12) {
                const latlng = e.latlng;
                const point = map.latLngToLayerPoint(latlng);

                mapData.forEach((polygon) => {
                    const { key, coordinates } = polygon;
                    const polygonPath = coordinates.map((coord) =>
                        coord&&map.latLngToLayerPoint(coord)
                    );

                    // Check if the mouse point is inside the polygon
                    if (isPointInsidePolygon(point, polygonPath)) {
                        setHighlightedPolygon(key);
                    }
                });
            }
        });
        useMapEvent('mousemove', (e) => {

        })
        return null;
    };
    const handleMouseOver = (e, polygonKey) => {
        setHighlightedPolygon(polygonKey);
    };

    const handleMouseOut = () => {
        setHighlightedPolygon(null);
    };
    const handleOnClick = () => {
        highlightedPolygon&&setOpen(true)
    };
    // function SetViewOnClick() {
    //     // const map = useMap();
    //     // map.setView(coords, map.getZoom());
    //     highlightedPolygon&&setOpen(true)
    //     return null;
    // }
    // const limeOptions = { color: 'white' }
    return (
        <>
        {!loaded&&(
            <h1>Loading ...........</h1>
        )}
        {loaded && (
            <>
            {open&&<FieldInfoDialog open={open} setOpen={setOpen} fieldId={highlightedPolygon}/>}
            <MapContainer
                classsName="map"
                center={[51.06691120579311, -105.53133533025485]}
                zoom={13}
                scrollWheelZoom={true}
                minZoom={10}
                maxZoom={15}
                // ref={mapRef}
                
            >
                <TileLayer
                    attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />
                <ChangeMapView />
                {/* {mapData &&
                    <Polygon pathOptions={limeOptions} positions={mapData} />
                } */}
                <LayersControl position="topright">
                    <LayersControl.Overlay checked name="Field boundary">
                        <LayerGroup>
                            {mapData && mapData.map((polygon,i) => (
                                <Polygon
                                    key={polygon.key}
                                    // key={i}
                                    positions={polygon.coordinates}
                                    eventHandlers={{
                                        mouseover: (e) => handleMouseOver(e, polygon.key),
                                        mouseout: handleMouseOut,
                                        click: handleOnClick
                                    }}
                                    pathOptions={{
                                        color: highlightedPolygon === polygon.key ? 'red' : 'white',
                                        weight: 2
                                    }}
                                >
                                    <Tooltip sticky>{polygon.key}</Tooltip>
                                </Polygon>
                            ))}
                        </LayerGroup>
                    </LayersControl.Overlay>
                </LayersControl>
                {/* <PolygonField fieldId={fieldId} aoi={aoi} summary={summary} openRiskDialog={openRiskDialog} /> */}
                {/* <SetViewOnClick /> */}
            </MapContainer>
            </>
        )}
        
        </>
    );
}

export default MapComp;
