import React from 'react';
import BoundaryIframe from '../components/BoundaryIframe';
import Header2 from '../components/Header2';
// import ResponsiveAppBar from '../components/ResponsiveAppBar'
function VirtualRainGauge() {
  return (
    <>
      <Header2 title="Virtual Rain Gauge"/>
      {/* <ResponsiveAppBar/> */}
      <BoundaryIframe url="https://realfield-398821.projects.earthengine.app/view/virtualrainguage" title="Virtual Rain Gauge" />
    </>
  );
}

export default VirtualRainGauge;
