import React, { useState, useEffect } from 'react';

function Header2({title="RealField"}) {
  
  return (
    <div id="header">
        <div id="logo">
          <img src="/images/agbio.png"/>
        </div>
        {/* <div id="slogan">{title}</div> */}
            
    </div>
  );
}
export default Header2;