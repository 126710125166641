import React from 'react';
import './App.css';
import { Routes, Route , Outlet} from 'react-router-dom';
import Home from "./pages/Home"
import VirtualRainGauge from "./pages/VirtualRainGauge"
import WheatYield from "./pages/WheatYield"
import TerraClimate from "./pages/TerraClimate"
import Boundary from "./pages/Boundary"
import TestPage from "./pages/TestPage"

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* <Route index element={<Home />} /> */}
        <Route index element={<VirtualRainGauge />} />
        <Route path="wheat-yield" element={<WheatYield />} />
        {/* <Route path="virtual-rain-guage" element={<VirtualRainGauge />} /> */}
        <Route path="terra-climate" element={<TerraClimate />} />
        <Route path="boundary" element={<Boundary />} />
        <Route path="testpage" element={<TestPage />} />
      </Route>      
    </Routes>
  );
}
function Layout() {
  return (
      <Outlet />    
  );
}
export default App